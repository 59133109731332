
import { call, put, takeEvery } from 'redux-saga/effects'
import * as types from '../../constants'
import { httpClient } from '../../util/Api'


//Saga call api
async function getUsersApi(params) {
    return await httpClient
    .get('users/admin', {params: params})
    .then(({data}) => {
        return data;
    })
    .catch(err => {
        throw err;
    })
}

async function deleteUsersByIdApi(id) {
    return await httpClient
    .delete(`users/admin/${id}`)
    .then(({data}) => {
        return data;
    })
    .catch(err => {
        throw err;
    })
}

async function getModalInfoShopApi(id) {
    return await httpClient
    .get(`shops/admin/${id}`)
    .then(({data}) => {
        return data;
    })
    .catch(err => {
        throw err;
    })
}

async function rejectShopApi(payload) {
    return await httpClient
    .put(`auth/verify/rejected-shop`, {...payload})
    .then(({data}) => {
        return data;
    })
    .catch(err => {
        throw err;
    })
}

async function approvedShopApi(shopId) {
    return await httpClient
    .get(`auth/verify/${shopId}/approved-shop`)
    .then(({data}) => {
        return data;
    })
    .catch(err => {
        throw err;
    })
}

//Saga worker
function* workerGetUserList(action) {
    try {
        const response = yield call(getUsersApi, action.payload);
        yield put({
            type: types.GET_USER_LIST_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.GET_USER_BY_ID_FAILED,
            payload: errResponse
        })
    }
};

function* workerGetTravelerList(action) {
    try {
        const response = yield call(getUsersApi, {...action.payload, searchUserType: 'traveler'});
        yield put({
            type: types.GET_TRAVELER_LIST_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.GET_USER_BY_ID_FAILED,
            payload: errResponse
        })
    }
};

function* workerGetPresenterList(action) {
    try {
        const response = yield call(getUsersApi, {...action.payload, searchUserType: 'presenter'});
        yield put({
            type: types.GET_PRESENTER_LIST_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.GET_USER_BY_ID_FAILED,
            payload: errResponse
        })
    }
};

function* workerGetShopList(action) {
    try {
        const response = yield call(getUsersApi, {...action.payload, searchUserType: 'shop'});
        yield put({
            type: types.GET_SHOP_LIST_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.GET_USER_BY_ID_FAILED,
            payload: errResponse
        })
    }
};

function* workerDeleteUserById(action) {
    try {
        const response = yield call(deleteUsersByIdApi, action.payload);
        yield put({
            type: types.DELETE_USER_BY_ID_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.DELETE_USER_BY_ID_FAILED,
            payload: errResponse
        })
    }
};

function* workerModalGetShopById(action) {
    try {
        const response = yield call(getModalInfoShopApi, action.payload);
        yield put({
            type: types.GET_SHOP_INFO_MODAL_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.GET_SHOP_INFO_MODAL_FAILED,
            payload: errResponse
        })
    }
};

function* workerModalRejectShop(action) {
    try {
        const response = yield call(rejectShopApi, {...action.payload});
        yield put({
            type: types.REJECT_SHOP_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.REJECT_SHOP_FAILED,
            payload: errResponse
        })
    }
};

function* workerModalApprovedShop(action) {
    try {
        const response = yield call(approvedShopApi, action.payload);
        yield put({
            type: types.APPROVED_SHOP_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.APPROVED_SHOP_FAILED,
            payload: errResponse
        })
    }
};

//Saga watcher
export function* watchGetUserList () {
    yield takeEvery(types.GET_USER_LIST, workerGetUserList);
}
export function* watchGetTravelerList () {
    yield takeEvery(types.GET_TRAVELER_LIST, workerGetTravelerList);
}
export function* watchGetPresenterList () {
    yield takeEvery(types.GET_PRESENTER_LIST, workerGetPresenterList);
}
export function* watchGetShopList () {
    yield takeEvery(types.GET_SHOP_LIST, workerGetShopList);
}
export function* watchDeleteUserById () {
    yield takeEvery(types.DELETE_USER_BY_ID, workerDeleteUserById);
}
export function* watchModalGetShopById () {
    yield takeEvery(types.SHOW_MODAL_APPROVED_SHOP, workerModalGetShopById);
}
export function* watchModalRejectShop () {
    yield takeEvery(types.REJECT_SHOP_EXCUTE, workerModalRejectShop);
}
export function* watchModalApprovedShop () {
    yield takeEvery(types.APPROVED_SHOP_EXCUTE, workerModalApprovedShop);
}