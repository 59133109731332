import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { hideModalApprovedShop, showModalRejectShop, approvedShop } from '../../appRedux/actions'
import { Link, useHistory } from "react-router-dom";
import { LinkToIcon } from "../icons"
import RejectShopModal from './RejectShopModal';

export default function ApprovedShopModal() {
    const modal = useSelector(state => state.modal.approvedShopModal)
    const dispatch = useDispatch();
    const history = useHistory();
    const [shopData, setShopData] = useState({
        userId: '',
        shopId: '',
        shopCreateAt: '',
        nickName: '',
        shopSatus: 'Unregistered',
        storeName: '',
        shopCreateDate: [],
        postalCode: '',
        addressDetail: '',
        phoneNumber: '',
        shopLink: '',
        shopDescription: '',
    })
    useEffect(() => {
        if (modal.data) {
            const data = modal.data;
            setShopData({
                ...shopData,
                userId: data.user.id,
                shopId: data.shop.id,
                shopCreateAt: data.shop.lastModifiedOnDate,
                nickName: data.user.nickName,
                shopSatus: data.shop.status,
                storeName: data.shop.storeName,
                shopCreateDate: data.shop.shopCreateDate.split('/'),
                postalCode: data.shop.address && data.shop.address.postalCode,
                addressDetail: data.shop.address && data.shop.address.addressDetail,
                phoneNumber: (data.shop.phoneCode || '') + (data.shop.phoneNumber || ''),
                shopLink: data.shop.shopLink,
                shopDescription: data.shop.storeDescription,
            })
        }
    }, [modal])
    const m = new Date(shopData.shopCreateAt);
    const dateString =
        m.getFullYear() + "." +
        ("0" + (m.getMonth() + 1)).slice(-2) + "." +
        ("0" + m.getDate()).slice(-2) + " " +
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2)

    const handleCancel = () => {
        dispatch(showModalRejectShop());
    }
    const handleConfirm = () => {
        dispatch(approvedShop(shopData.shopId));
        setTimeout(() => {
            hideModalApprovedShop();
            history.push('/');
        }, 1000)
    }
    const handleCloseModal = () => {
        dispatch(hideModalApprovedShop());
    }
    const isShopActive = shopData.shopSatus === 'Active' || shopData.shopSatus === 'Confirmed';
    let shopLink = shopData.shopLink || null;
    if (shopLink) {
        const regex = new RegExp(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/)
        if (!regex.test(shopLink)) {
            shopLink = 'https://' + shopLink;
        }
    }
    return (
        <React.Fragment >
            <Modal className="modal-custom modal-shop"
                mask={false}
                visible={modal.visible}
                footer={false} width={1152}
                onCancel={handleCloseModal}
            >
                <div className="wrap-content">
                    <div className="shop-info-header">
                        {shopData.shopSatus === 'New' && <div className="store-certification store-certification-waiting">認証待ち</div>}
                        {isShopActive && <div className="store-certification store-certification-approved">認証済み</div>}
                        <div className="store-title">{shopData.storeName || '-'}</div>
                        <div className={`btn-view-info ${isShopActive ? 'active' : ''}`}
                            onClick={isShopActive ? handleCloseModal : undefined}
                        >
                            {
                                isShopActive ? <Link to={`/user-detail/${shopData.userId}`}>ショップ情報 <LinkToIcon /></Link> :
                                    <div>ショップ情報 <LinkToIcon /></div>
                            }
                        </div>
                    </div>
                    <div className="info-regist-shop">
                        <div className="date-regist-shop">申請日時：{dateString}</div>
                        <div className="user-nickname">申請者：<Link to={`/user-detail/${shopData.userId}`} onClick={handleCloseModal}>{shopData.nickName}</Link></div>
                    </div>
                    <div className="row-shop-data mb-40">
                        <div className="raw-data">
                            <div className="data-title">店舗名</div>
                            <div className="data-value">{shopData.storeName || '-'}</div>
                        </div>
                        <div className="raw-data">
                            <div className="data-title">設立年月</div>
                            <div className="data-value">{shopData.shopCreateDate.length ? `${shopData.shopCreateDate[1]}年　${`0${shopData.shopCreateDate[0]}`.slice(-2)}月` : '-'}</div>
                        </div>
                        <div className="raw-data">
                            <div className="data-title">店舗住所</div>
                            <div className="data-value">{shopData.postalCode ? `〒${shopData.postalCode.slice(0, 3)}-${shopData.postalCode.slice(3)}　${shopData.addressDetail}` : '-'}</div>
                        </div>
                        <div className="raw-data">
                            <div className="data-title">電話番号</div>
                            <div className="data-value">{shopData.phoneNumber || '-'}</div>
                        </div>
                        <div className="raw-data">
                            <div className="data-title">お店のホームページ</div>
                            <div className="data-value">{shopLink ? <a className='sample-link' target='_blank' href={shopLink} rel="noreferrer">{shopLink}</a> : '無し'}</div>
                        </div>
                        <div className="raw-data">
                            <div className="data-title">お店についての説明</div>
                            <div className="data-value break-line">{shopData.shopDescription || '-'}</div>
                        </div>
                    </div>
                    {
                        shopData.shopSatus === 'New' &&
                        <div className="footer-btn-wrap">
                            <div className="btn-certification btn-cancel" onClick={handleCancel}>却下する</div>
                            <div className="btn-certification btn-accept" onClick={handleConfirm}>承認する</div>
                        </div>
                    }
                </div>
            </Modal>
            <RejectShopModal />
        </React.Fragment>
    )
}
