import React from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { hideModalExportCSVSuccess } from '../../appRedux/actions'

export default function ExportCSVSuccessModal() {
    const modal = useSelector(state => state.modal.exportCSVSuccessModal);
    const dispatch = useDispatch();

    return (
        <Modal className="modal-custom" width={390} visible={modal.visible} footer={false} closeIcon={false} centered>
            <div className="modal-message mb-40 mt-16">CSVデータが出力されました</div>
            <div className="modal-footer" style={{justifyContent: 'center'}}>
                <div className="modal-btn" onClick={() => {
                    dispatch(hideModalExportCSVSuccess());
                }}>戻る</div>
            </div>
      </Modal>
    )
}
