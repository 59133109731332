import * as types from '../../constants/ModalTypes'

export const showModalLogout = () => {
    return {
        type: types.SHOW_MODAL_LOGOUT,
    }
}
export const hideModalLogout = () => {
    return {
        type: types.HIDE_MODAL_LOGOUT,
    }
}
export const showModalDeleteUser = (userId, username) => {
    return {
        type: types.SHOW_MODAL_DELTE_USER,
        payload: { userId, username }
    }
}
export const hideModalDeleteUser = () => {
    return {
        type: types.HIDE_MODAL_DELTE_USER,
    }
}
export const showModalExportCSV = () => {
    return {
        type: types.SHOW_MODAL_EXPORT_CSV,
    }
}
export const hideModalExportCSV = () => {
    return {
        type: types.HIDE_MODAL_EXPORT_CSV,
    }
}
export const showModalApprovedShop = (id) => {
    return {
        type: types.SHOW_MODAL_APPROVED_SHOP,
        payload: id
    }
}
export const hideModalApprovedShop = () => {
    return {
        type: types.HIDE_MODAL_APPROVED_SHOP,
    }
}
export const deleteUserById = (id) => {
    return {
        type: types.DELETE_USER_BY_ID,
        payload: id
    }
}
export const showModalRejectShop = () => {
    return {
        type: types.SHOW_MODAL_REJECT_SHOP,
    }
}
export const hideModalRejectShop = () => {
    return {
        type: types.HIDE_MODAL_REJECT_SHOP,
    }
}
export const approvedShop = (shopId) => {
    return {
        type: types.APPROVED_SHOP_EXCUTE,
        payload: shopId,

    }
}
export const rejectShop = (shopId, resonReject) => {
    return {
        type: types.REJECT_SHOP_EXCUTE,
        payload: {
            shopId: shopId,
            resonReject: resonReject
        },
    }
}
export const showModalExportCSVSuccess = () => {
    return {
        type: types.SHOW_MODAL_EXPORT_CSV_SUCCESS,
    }
}
export const hideModalExportCSVSuccess = () => {
    return {
        type: types.HIDE_MODAL_EXPORT_CSV_SUCCESS,
    }
}
