import * as types from '../../constants/VariableTypes'

export const fetchProvince = () => {
    return {
        type: types.GET_PROVINCE_LIST
    }
}
export const fetchCategory = () => {
    return {
        type: types.GET_CATEGORY_LIST
    }
}