import React from 'react'

export default function LoginErrorLog({errorCode}) {
    let errorMessage = '';
    switch(errorCode) {
        case 'EMAIL_OR_PASSWORD_IS_INCORRECT':
            errorMessage = 'メールアドレスまたはパスワードが正しくありません';
            break;
        case 'SERVER_ERROR':
            errorMessage = 'エラーが発生しました。再度お試しください';
            break;
        default:
            errorMessage = 'エラーが発生しました。再度お試しください';
            break;
    }
    return (
        <div className="gx-app-login-main-content-err">
            <div style={{display: errorCode ? 'block':'none'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path id="Path_1006" data-name="Path 1006" d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm.8,12H9.2V12.4h1.6Zm0-3.2c-.4,1.2-1.6,0-1.6,0V6h1.6S11.2,9.6,10.8,10.8Z" transform="translate(-2 -2)" fill="#ff0023"/>
                </svg>
                {errorMessage}
            </div>
        </div>
    )
}
