import * as types from '../../constants/UserTypes'

export const getUserPagination = (pagination) => {
    return {
        type: types.GET_USER_LIST,
        payload: pagination
    }
}

export const getTravelerPagination = (pagination) => {
    return {
        type: types.GET_TRAVELER_LIST,
        payload: pagination
    }
}

export const getPresenterPagination = (pagination) => {
    return {
        type: types.GET_PRESENTER_LIST,
        payload: pagination
    }
}

export const getShopPagination = (pagination) => {
    return {
        type: types.GET_SHOP_LIST,
        payload: pagination
    }
}