import { Link } from 'react-router-dom'
import logoImg from '../../../assets/vendors/images/logo.svg';
import { useDispatch } from 'react-redux'
import { showModalLogout } from '../../../appRedux/actions';

export default function Navbar(props) {
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(showModalLogout());
    }

    return (
        <div className="topbar-default">
            <div className="topbar-content">
                <div className="topbar-content-logo"><Link to='/'><img src={logoImg} alt="Logo" /></Link></div>
                <div style={{
                    display: 'flex'
                }}>
                    <div className="topbar-content-logout"
                        style={{ marginRight: 40, color: '#FFFFFF' }}
                    >
                        <Link to={`/`}>
                            <span style={{ color: '#FFFFFF' }}>ユーザ一覧</span>
                        </Link>
                    </div>
                    <div className="topbar-content-logout">
                        <Link to={`/payment-management`}>
                            <span style={{ color: '#FFFFFF' }}> 支払管理</span>
                        </Link>
                    </div>
                    <div style={{
                        border: 1,
                        borderStyle: 'solid',
                        marginRight: 30,
                        marginLeft: 30,
                        color: '#FFFFFF'
                    }} />
                    <div className="topbar-content-logout" onClick={handleLogout}>
                        ログアウト
                    </div>
                </div>
            </div>
        </div>
    )
}
