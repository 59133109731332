import React, { useState } from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {useAuth} from "../authentication";
import LoginErrorLog from "../components/LoginErrorLog";
import logoImg from '../assets/vendors/images/logo.svg';

const SignIn = () => {
  const {error, userLogin} = useAuth();
  const [ rememberCheck, setRememberCheck ] = useState(false);
  const handleChangeCheckbox = (e) => {
    setRememberCheck(e.target.checked);
  }

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    userLogin({ ...values, remember: rememberCheck });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-main-content-logo mb-20"><img src={logoImg} alt="Logo"/></div>
          <LoginErrorLog errorCode={error}/>
        <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="gx-signin-form gx-app-login-main-content-form">
              <Form.Item
                rules={[{ required: true }]} name="email" className="mb-30" label="メールアドレス">
                <Input className="gx-app-login-main-content-field mb-10" type="email" placeholder="入力してください"/>
              </Form.Item>
              <Form.Item
                rules={[{ required: true }]} name="password" label="パスワード">
                <Input className="gx-app-login-main-content-field mb-10" type="password" placeholder="入力してください"/>
              </Form.Item>
              <Form.Item name="remember">
                <Checkbox className="gx-app-login-main-content-checkbox mb-20" checked={rememberCheck} onChange={(e) => handleChangeCheckbox(e)}>次回から自動ログインする</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button className="gx-app-login-main-content-btn-login" type="primary" htmlType="submit">ログイン</Button>
              </Form.Item>
            </Form>
          {/* <AppNotificationContainer loading={isLoading} error={error}/> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
