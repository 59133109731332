import { all, fork } from 'redux-saga/effects'
import { 
    watchGetUserList, 
    watchGetTravelerList, 
    watchGetPresenterList, 
    watchGetShopList, 
    watchDeleteUserById, 
    watchModalGetShopById, 
    watchModalRejectShop, 
    watchModalApprovedShop 
} from './User'
import { watchGetProvinceList, watchGetCategoryList} from './Variable'

export default function* rootSaga() {
    yield all([
        fork(watchGetUserList),
        fork(watchGetTravelerList),
        fork(watchGetPresenterList),
        fork(watchGetShopList),
        fork(watchDeleteUserById),
        fork(watchModalGetShopById),
        fork(watchModalRejectShop),
        fork(watchModalApprovedShop),
        fork(watchGetProvinceList),
        fork(watchGetCategoryList),
    ])
}