import React, { useState } from "react";
import { Modal, Radio, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModalExportCSV,
  showModalExportCSVSuccess,
} from "../../appRedux/actions";
import { DropDownIcon } from "../icons";
import locale from "antd/es/date-picker/locale/ja_JP";
import moment from "moment";
import "moment/locale/zh-cn";
import RejectShopModal from "./RejectShopModal";
import { httpClient } from "../../util/Api";

export default function ExportCSVModal() {
  const [radioExport, setRadioExport] = useState({
    date: true,
    all: false,
  });
  const [dateQuery, setDateQuery] = useState({
    startDate: null,
    endDate: null,
  });
  const modal = useSelector((state: any) => state.modal.exportCSVModal);
  const dispatch = useDispatch();
  const downloadFileCSV = async (
    exportType: string,
    startDate?: string,
    endDate?: string
  ) => {
    return await httpClient
      .post("schedule/admin/export-csv", { exportType, startDate, endDate })
      .then(async (response) => {
        await httpClient
          .get(`schedule/admin/get-file-csv/${response.data}`, {
            responseType: "arraybuffer",
          })
          .then(({ data }) => {
            const bb = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            let fileName = `Export List Warp_All.csv`;
            if (exportType === "date" && startDate && endDate) {
              fileName = `Export List Warp_${startDate?.replace(
                /-/g,
                ""
              )}-${endDate?.replace(/-/g, "")}.csv`;
            }
            bb.text().then((text) => {
              let blob = new Blob(["\uFEFF" + text], {
                type: "text/csv; charset=utf-8",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.setAttribute("download", fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              clearStateModal();
              dispatch(showModalExportCSVSuccess());
            });
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleConfirm = async () => {
    if (radioExport.date) {
      if (dateQuery.startDate && dateQuery.endDate) {
        // const startDateTime = new Date(dateQuery.startDate).getTime();
        const startDateTime = moment(dateQuery.startDate)
          .startOf("date")
          .toISOString();
        const endDateTime = moment(dateQuery.endDate)
          .endOf("date")
          .toISOString();
        // const offsetTZ = new Date().getTimezoneOffset();
        // const startDateByLocal = new Date(
        //   startDateTime + -1 * offsetTZ * 60 * 1000
        // );
        // const endDateByLocal = new Date(
        //   endDateTime + -1 * offsetTZ * 60 * 1000
        // );
        await downloadFileCSV("date", startDateTime, endDateTime);
      }
    } else {
      await downloadFileCSV("all");
    }
  };
  const handleCancel = () => {
    dispatch(hideModalExportCSV());
    clearStateModal();
  };
  const handleClickRadio = (e) => {
    if (e.target.value === "date") {
      setRadioExport({
        date: true,
        all: false,
      });
    } else {
      setRadioExport({
        date: false,
        all: true,
      });
    }
  };
  const onChangeStartDate = (date, dateString) => {
    const dateStr = dateString.replace(/\./g, "-");
    if (
      !dateQuery.endDate ||
      (dateQuery.endDate && new Date(dateQuery.endDate) >= new Date(dateStr))
    ) {
      setDateQuery({
        ...dateQuery,
        startDate: dateStr,
      });
    }
  };
  const onChangeEndDate = (date, dateString) => {
    const dateStr = dateString.replace(/\./g, "-");
    if (
      !dateQuery.startDate ||
      (dateQuery.startDate &&
        new Date(dateQuery.startDate) <= new Date(dateStr))
    ) {
      setDateQuery({
        ...dateQuery,
        endDate: dateStr,
      });
    }
  };
  const disabledStartDate = (current) => {
    if (dateQuery.endDate) {
      return current && current > moment(dateQuery.endDate);
    }
    return false;
  };
  const disabledEndDate = (current) => {
    if (dateQuery.startDate) {
      return current && current < moment(dateQuery.startDate);
    }
    return false;
  };
  const clearStateModal = () => {
    setDateQuery({
      startDate: null,
      endDate: null,
    });
    setRadioExport({
      date: true,
      all: false,
    });
  };
  return (
    <React.Fragment>
      <Modal
        className="modal-custom model-export-csv"
        mask={false}
        visible={modal.visible}
        footer={false}
        width={412}
        closeIcon={false}
        centered
      >
        <div className="wrap-modal-export">
          <div className="modal-title">CSV出力</div>
          <Radio
            value={"date"}
            checked={radioExport.date}
            onClick={handleClickRadio}
          >
            期間を選択
          </Radio>
          <div className="wrap-date-picker">
            <div className="choose-date">
              <div className="choose-date-title">開始日</div>
              <DatePicker
                format={"YYYY.MM.DD"}
                locale={locale}
                value={
                  dateQuery.startDate
                    ? moment(dateQuery.startDate, "YYYY.MM.DD")
                    : null
                }
                placeholder="YYYY.MM.DD"
                autoFocus={false}
                allowClear={false}
                suffixIcon={<DropDownIcon />}
                onChange={onChangeStartDate}
                disabledDate={disabledStartDate}
                showToday={false}
                disabled={radioExport.all === true}
              />
            </div>
            <div className="choose-date-space">～</div>
            <div className="choose-date">
              <div className="choose-date-title">終了日</div>
              <DatePicker
                format={"YYYY.MM.DD"}
                locale={locale}
                value={
                  dateQuery.endDate
                    ? moment(dateQuery.endDate, "YYYY.MM.DD")
                    : null
                }
                placeholder="YYYY.MM.DD"
                allowClear={false}
                suffixIcon={<DropDownIcon />}
                onChange={onChangeEndDate}
                disabledDate={disabledEndDate}
                showToday={false}
                onSelect={(value) => value}
                disabled={radioExport.all === true}
              />
            </div>
          </div>
          <Radio
            value={"all"}
            checked={radioExport.all}
            onClick={handleClickRadio}
          >
            すべての期間をエクスポート
          </Radio>
          <div className="modal-footer">
            <div className="modal-btn" onClick={handleCancel}>
              戻る
            </div>
            <div
              className={`modal-btn btn-confirm ${
                ((radioExport.date &&
                  dateQuery.startDate &&
                  dateQuery.endDate) ||
                  radioExport.all) &&
                "active"
              }`}
              onClick={handleConfirm}
            >
              出力
            </div>
          </div>
        </div>
      </Modal>
      <RejectShopModal />
    </React.Fragment>
  );
}
