import React, { useState } from 'react'
import { Modal, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { hideModalRejectShop, rejectShop } from '../../appRedux/actions'
import { useHistory } from "react-router-dom";

const { TextArea } = Input;

function mbStrWidth(input) {
    let len = 0;
    for (let i = 0; i < input.length; i++) {
        let code = input.charCodeAt(i);
        if ((code >= 0x0020 && code <= 0x1FFF) || (code >= 0xFF61 && code <= 0xFF9F)) {
            len += 1;
        } else if ((code >= 0x2000 && code <= 0xFF60) || (code >= 0xFFA0)) {
            len += 2;
        } else {
            len += 0;
        }
        if(len >= 360) {
            return input.slice(0, i + 1);
        }
    }
    return input;
}

export default function RejectShopModal() {
    const [resonText, setResonText] = useState('');
    const modal = useSelector(state => state.modal.approvedShopModal);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleDelete = () => {
        dispatch(rejectShop(modal.shopId, resonText));
        setResonText('');
        history.push('/');
    }
    const handleChangInput = (e) => {
        const input = e.target.value;
        setResonText(mbStrWidth(input));

    }
    return (
        <Modal className="modal-custom" width={390} visible={modal.modalReject.visible} footer={false} closeIcon={false} centered>
            <div className="modal-message mb-16">ショップ申請を却下しますか？</div>
            <div className="modal-input mb-24 mr-40 ml-40">
                <div className="title mb-8">却下理由</div>
                <TextArea
                    value={resonText}
                    onChange={handleChangInput}
                    placeholder="入力してください"
                    autoSize={{ minRows: 6 }}
                />
            </div>
            <div className="modal-footer">
                <div className="modal-btn" onClick={() => {
                    dispatch(hideModalRejectShop());
                    setResonText('');
                }}>戻る</div>
                <div className={`modal-btn btn-delete ${resonText && 'active'}`}
                 onClick={resonText ? handleDelete : undefined}>却下する</div>
            </div>
      </Modal>
    )
}
