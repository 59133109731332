import React from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { hideModalDeleteUser, deleteUserById } from '../../appRedux/actions'
import { useHistory } from "react-router-dom";
export default function DeleteUserModal() {
    const modal = useSelector(state => state.modal.deleteUserModal)
    const dispatch = useDispatch();
    const history = useHistory();

    const handleDelete = () => {
        dispatch(deleteUserById(modal.data.userId));
        setTimeout(history.push('/'), 1000);
    }
    return (
        modal && modal.data && <Modal className="modal-custom" width={390} visible={modal.visible} footer={false} closeIcon={false} centered>
            <div className="modal-message mb-20">{modal.data.username}さんを <br/> 強制的に退会させますか？</div>
            <div className="modal-submessage mb-20">
                <div>※すべてのデータベースからデータが削除されます</div>
                <div>※この操作は元に戻せません</div>
            </div>
            <div className="modal-footer">
                <div className="modal-btn" onClick={() => {
                    dispatch(hideModalDeleteUser());
                }}>戻る</div>
                <div className="modal-btn btn-delete active"onClick={handleDelete}>削除する</div>
            </div>
      </Modal>
    )
}
