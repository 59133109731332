export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILED = 'GET_USER_LIST_FAILED';
export const GET_TRAVELER_LIST = 'GET_TRAVELER_LIST';
export const GET_TRAVELER_LIST_SUCCESS = 'GET_TRAVELER_LIST_SUCCESS';
export const GET_TRAVELER_LIST_FAILED = 'GET_TRAVELER_LIST_FAILED';
export const GET_PRESENTER_LIST = 'GET_PRESENTER_LIST';
export const GET_PRESENTER_LIST_SUCCESS = 'GET_PRESENTER_LIST_SUCCESS';
export const GET_PRESENTER_LIST_FAILED = 'GET_PRESENTER_LIST_FAILED';
export const GET_SHOP_LIST = 'GET_SHOP_LIST';
export const GET_SHOP_LIST_SUCCESS = 'GET_SHOP_LIST_SUCCESS';
export const GET_SHOP_LIST_FAILED = 'GET_SHOP_LIST_FAILED';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAILED = 'GET_USER_BY_ID_FAILED';