import Icon from '@ant-design/icons';

const SearchSvg = () => (
    <svg id="icon-search" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <rect id="Rectangle_18" data-name="Rectangle 18" width="22" height="22" fill="rgba(255,255,255,0)"/>
        <path id="Path_1001" data-name="Path 1001" d="M447.083,30.7a.653.653,0,0,0,.429-.173l5.233-5.276a5.657,5.657,0,0,0,3.689,1.3,5.924,5.924,0,0,0,4.2-10.119,5.806,5.806,0,0,0-4.2-1.73,5.883,5.883,0,0,0-5.92,5.968,5.76,5.76,0,0,0,1.287,3.719l-5.233,5.276a.683.683,0,0,0,0,.951A1.08,1.08,0,0,0,447.083,30.7Zm6.091-13.319a4.63,4.63,0,1,1,3.26,7.957c-1.2,0-3.26-1.384-3.26-1.384a8.657,8.657,0,0,1-1.373-3.286A4.745,4.745,0,0,1,453.174,17.381Z" transform="translate(-442.375 -10.518)" fill="#3b3940" fillRule="evenodd" opacity="0.5"/>
    </svg>
);
const ExportCSVSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="icon-export" transform="translate(-861.5 -731)">
      <rect id="Rectangle_1336" data-name="Rectangle 1336" width="24" height="24" transform="translate(861.5 731)" fill="rgba(255,255,255,0)"/>
      <path id="Path_1176" data-name="Path 1176" d="M1972.491,3501.029a1.454,1.454,0,0,0-1.454,1.455v4.364a1.454,1.454,0,0,0,1.454,1.455h13.091a1.454,1.454,0,0,0,1.455-1.455v-4.364a1.454,1.454,0,0,0-1.455-1.455" transform="translate(-1105.537 -2758.211)" fill="none" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <path id="Path_1177" data-name="Path 1177" d="M2039.857,3519.029l-2.909,2.909-2.909-2.909" transform="translate(-1163.447 -2774.756)" fill="none" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <line id="Line_167" data-name="Line 167" y1="10.182" transform="translate(873.5 737)" fill="none" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    </g>
  </svg>
);
const DetailUserSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="icon_arrow" transform="translate(-335 -161)">
            <rect id="Rectangle_1293" data-name="Rectangle 1293" width="24" height="24" transform="translate(335 161)" fill="rgba(0,0,0,0)"/>
            <path id="Path_858" data-name="Path 858" d="M194,217V199h10.125l3.375,3.938h0V217Zm1.125-16.875v15.806h11.25V204.062h-3.881v-3.937Zm8.438.169v2.644h2.25Z" transform="translate(146 -35)" fill="#3b3940"/>
        </g>
    </svg>
);
const ArrowLinkSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.415" height="11.415" viewBox="0 0 11.415 11.415">
        <g id="Group_65" data-name="Group 65" transform="translate(-768.293 -2203.279)">
            <path id="Path_11" data-name="Path 11" d="M539.1,2311.3l10-10" transform="translate(229.9 -97.314)" fill="none" stroke="#ff0023" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
            <path id="Path_12" data-name="Path 12" d="M551.647,2309.247V2301.3H543.7" transform="translate(227.354 -97.314)" fill="none" stroke="#ff0023" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
        </g>
    </svg>
);
const SortSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6">
        <path id="Path_1179" data-name="Path 1179" d="M4,0,8,6H0Z" transform="translate(8 6) rotate(180)" fill="#8a838b"/>
    </svg>
);
const FilterSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6">
        <path id="Path_1153" data-name="Path 1153" d="M6.5,8,2,3.377,3.34,2,6.5,5.246,9.66,2,11,3.377Z" transform="translate(-2 -2)" fill="#3b3940"/>
    </svg>
);
const TrashSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_1675" data-name="Group 1675" transform="translate(-1015 -88)">
            <rect id="Rectangle_1323" data-name="Rectangle 1323" width="24" height="24" transform="translate(1015 88)" fill="rgba(138,131,139,0)"/>
            <g id="icon_delete" transform="translate(-4210.037 -3321.029)">
            <path id="Path_1150" data-name="Path 1150" d="M5238.037,3447.029v14.222a1.777,1.777,0,0,0,1.778,1.778h10.667a1.777,1.777,0,0,0,1.778-1.778v-14.222" transform="translate(-8.111 -32.445)" fill="rgba(0,0,0,0)" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_145" data-name="Line 145" x2="16" transform="translate(5229.037 3414.584)" fill="rgba(0,0,0,0)" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_146" data-name="Line 146" y2="8.889" transform="translate(5233.481 3418.14)" fill="rgba(0,0,0,0)" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_147" data-name="Line 147" y2="8.889" transform="translate(5237.037 3418.14)" fill="rgba(0,0,0,0)" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_148" data-name="Line 148" y2="8.889" transform="translate(5240.593 3418.14)" fill="rgba(0,0,0,0)" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_1151" data-name="Path 1151" d="M5281.148,3414.584v-1.778a1.777,1.777,0,0,0-1.778-1.778h-3.556a1.777,1.777,0,0,0-1.778,1.778v1.778" transform="translate(-40.556 0)" fill="rgba(0,0,0,0)" stroke="#8a838b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            </g>
        </g>
    </svg>
);

const LinkToSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18">
        <path id="chevron-right" d="M16.393,17,10.271,9.857a1.125,1.125,0,1,1,1.708-1.464l6.75,7.875a1.125,1.125,0,0,1,0,1.464l-6.75,7.875a1.125,1.125,0,0,1-1.708-1.464Z" transform="translate(-10 -8)" fill="#fff"/>
    </svg>
);

const RectangleTravelerSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <rect id="Rectangle_1316" data-name="Rectangle 1316" width="16" height="16" fill="#fa6afd"/>
    </svg>
);

const RectanglePresenterSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <rect id="Rectangle_1317" data-name="Rectangle 1317" width="16" height="16" fill="#00d0e2"/>
    </svg>
);

const RectangleShopSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <rect id="Rectangle_1337" data-name="Rectangle 1337" width="16" height="16" fill="#131d36"/>
    </svg>
);

const BlackLinkShopSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.415" height="11.415" viewBox="0 0 11.415 11.415">
        <g id="Group_65" data-name="Group 65" transform="translate(-768.293 -2203.279)">
            <path id="Path_11" data-name="Path 11" d="M539.1,2311.3l10-10" transform="translate(229.9 -97.314)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
            <path id="Path_12" data-name="Path 12" d="M551.647,2309.247V2301.3H543.7" transform="translate(227.354 -97.314)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
        </g>
    </svg>
);

const CloseModalSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="icon-cross" transform="translate(-1197 -100)">
            <rect id="Rectangle_1338" data-name="Rectangle 1338" width="24" height="24" transform="translate(1197 100)" fill="rgba(255,255,255,0)"/>
            <path id="Union_6" data-name="Union 6" d="M-3049.463-184.989l-8,8,8-8-8-8,8,8,8-8-8,8,8,8Z" transform="translate(4258.963 297.489)" fill="none" stroke="#3b3940" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        </g>
    </svg>
);

const ClearSearchSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="icon-cross" transform="translate(-1197 -100)">
        <rect id="Rectangle_1338" data-name="Rectangle 1338" width="24" height="24" rx="12" transform="translate(1197 100)" fill="rgba(255,255,255,0)"/>
        <path id="Union_6" data-name="Union 6" d="M-3051.463-186.989l-6,6,6-6-.375-.375-5.625-5.625,6,6,6-6-6,6,6,6Z" transform="translate(4260.963 299.489)" fill="none" stroke="#3b3940" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" opacity="0.5"/>
        </g>
    </svg>  
);

const DropDownSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6">
        <path id="Path_36" data-name="Path 36" d="M6.5,8,2,3.377,3.34,2,6.5,5.246,9.66,2,11,3.377Z" transform="translate(-2 -2)" fill="#3b3940"/>
    </svg>
)

const EmptyBoxSVG = () => (
    <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
            <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
            <g className="ant-empty-img-simple-g" fillRule="nonzero">
                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path>
            </g>
        </g>
    </svg>
)

export const ExportCSVIcon = props => <Icon component={ExportCSVSvg} {...props} />;
export const SearchIcon = props => <Icon component={SearchSvg} {...props} />;
export const DetailUserIcon = props => <Icon component={DetailUserSvg} {...props} />;
export const ArrowLinkIcon = props => <Icon component={ArrowLinkSvg} {...props} />;
export const SortIcon = props => <Icon component={SortSvg} {...props} />;
export const FilterIcon = props => <Icon component={FilterSvg} {...props} />;
export const TrashIcon = props => <Icon component={TrashSvg} {...props} />;
export const LinkToIcon = props => <Icon component={LinkToSVG} {...props} />;
export const RectangleTravelerIcon = props => <Icon component={RectangleTravelerSVG} {...props} />;
export const RectanglePresenterIcon = props => <Icon component={RectanglePresenterSVG} {...props} />;
export const RectangleShopIcon = props => <Icon component={RectangleShopSVG} {...props} />;
export const BlackLinkShopIcon = props => <Icon component={BlackLinkShopSVG} {...props} />;
export const CloseModalIcon = props => <Icon component={CloseModalSVG} {...props} />;
export const ClearSearchIcon = props => <Icon component={ClearSearchSVG} {...props} />;
export const DropDownIcon = props => <Icon component={DropDownSVG} {...props} />;
export const EmptyBoxIcon = props => <Icon component={EmptyBoxSVG} {...props} />;


