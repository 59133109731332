import React from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { hideModalLogout } from '../../appRedux/actions'
import { useAuth } from '../../authentication';
import { useHistory } from "react-router-dom";
export default function LogoutModal() {
    const modal = useSelector(state => state.modal.logoutModal);
    const dispatch = useDispatch();
    const { userSignOut } = useAuth();
    const history = useHistory();

    const handleLogout = () => {
        dispatch(hideModalLogout());
        userSignOut(() => {
            history.push('/');
        });
    }
    return (
        <Modal className="modal-custom" width={390} visible={modal.visible} footer={false} closeIcon={false} centered>
            <div className="modal-message mb-40">ログアウトしますか？</div>
            <div className="modal-footer">
                <div className="modal-btn" onClick={() => {
                    dispatch(hideModalLogout());
                }}>戻る</div>
                <div className="modal-btn btn-confirm active"onClick={handleLogout}>ログアウト</div>
            </div>
      </Modal>
    )
}
