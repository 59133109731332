import { call, put, takeEvery } from 'redux-saga/effects'
import * as types from '../../constants/VariableTypes'
import { httpClient } from '../../util/Api'

//Saga call api
async function getProvincesApi() {
    return await httpClient
    .get('variable/admin/province')
    .then(({data}) => {
        return data;
    })
    .catch(err => {
        throw err;
    })
}

async function getCategoriesApi() {
    return await httpClient
    .get('variable/admin/category')
    .then(({data}) => {
        return data;
    })
    .catch(err => {
        throw err;
    })
}

//Saga worker
function* workerGetProvince() {
    try {
        const response = yield call(getProvincesApi);
        yield put({
            type: types.GET_PROVINCE_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.GET_PROVINCE_FAILED,
            payload: errResponse
        })
    }
};

function* workerGetCategory() {
    try {
        const response = yield call(getCategoriesApi);
        yield put({
            type: types.GET_CATEGORY_SUCCESS,
            payload: response
        })
    }catch (err) {
        let errResponse = err.message;
        if(err.response && err.response.data) {
            errResponse = err.response.data.errorCode;
        }
        yield put({
            type: types.GET_CATEGORY_FAILED,
            payload: errResponse
        })
    }
};

//Saga watcher
export function* watchGetProvinceList () {
    yield takeEvery(types.GET_PROVINCE_LIST, workerGetProvince);
}
export function* watchGetCategoryList () {
    yield takeEvery(types.GET_CATEGORY_LIST, workerGetCategory);
}