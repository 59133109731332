import * as types from '../../constants/ModalTypes'

const initialModal = {
    logoutModal: {
        visible: false,
    },
    deleteUserModal: {
        visible: false,
        data: null,
        loading: false,
        errMessage: '',
    },
    exportCSVModal: {
        visible: false,
    },
    exportCSVSuccessModal: {
        visible: false,
    },
    approvedShopModal: {
        visible: false,
        modalReject: {
            visible: false,
        },
        shopId: null,
        data: null,
        loading: false,
        errMessage: '',
    },
};
  
  const ModalReducer = (state = initialModal, action) => {
    switch (action.type) {
        //Action send request and wait
        case types.SHOW_MODAL_LOGOUT: {
            return {
                ...state,
                logoutModal: {
                    visible: true
                }
            }
        }
        case types.SHOW_MODAL_DELTE_USER: {
            return {
                ...state,
                deleteUserModal: {
                    ...state.deleteUserModal,
                    visible: true,
                    data: action.payload,
                }
            }
        }
        case types.SHOW_MODAL_EXPORT_CSV: {
            return {
                ...state,
                exportCSVModal: {
                    visible: true
                }
            }
        }
        case types.SHOW_MODAL_EXPORT_CSV_SUCCESS: {
            return {
                ...state,
                exportCSVSuccessModal: {
                    visible: true
                },
                exportCSVModal: {
                    visible: false
                }
            }
        }
        case types.SHOW_MODAL_APPROVED_SHOP: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    visible: true,
                    loading: true,
                    shopId: action.payload
                }
            }
        }
        case types.HIDE_MODAL_LOGOUT: {
            return {
                ...state,
                logoutModal: {
                    visible: false
                }
            }
        }
        case types.HIDE_MODAL_DELTE_USER: {
            return {
                ...state,
                deleteUserModal: {
                    ...state.deleteUserModal,
                    visible: false
                }
            }
        }
        case types.HIDE_MODAL_EXPORT_CSV: {
            return {
                ...state,
                exportCSVModal: {
                    visible: false
                }
            }
        }
        case types.HIDE_MODAL_EXPORT_CSV_SUCCESS: {
            return {
                ...state,
                exportCSVSuccessModal: {
                    visible: false
                }
            }
        }
        case types.HIDE_MODAL_APPROVED_SHOP: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    loading: false,
                    visible: false
                }
            }
        }
        case types.DELETE_USER_BY_ID: {
            return {
                ...state,
                deleteUserModal: {
                    ...state.deleteUserModal,
                    loading: true,
                }
            }
        }
        case types.DELETE_USER_BY_ID_SUCCESS: {
            return {
                ...state,
                deleteUserModal: {
                    ...state.deleteUserModal,
                    visible: false,
                    loading: false,
                }
            }
        }
        case types.DELETE_USER_BY_ID_FAILED: {
            return {
                ...state,
                deleteUserModal: {
                    ...state.deleteUserModal,
                    visible: false,
                    loading: false,
                    errMessage: action.payload
                }
            }
        }
        case types.GET_SHOP_INFO_MODAL_SUCCESS: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    loading: false,
                    data: action.payload
                }
            }
        }
        case types.GET_SHOP_INFO_MODAL_FAILED: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    visible: false,
                    loading: false,
                    errMessage: action.payload
                }
            }
        }
        case types.SHOW_MODAL_REJECT_SHOP: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    modalReject: {
                        ...state.approvedShopModal.modalReject,
                        visible: true,
                    }
                }
            }
        }
        case types.HIDE_MODAL_REJECT_SHOP: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    modalReject: {
                        ...state.approvedShopModal.modalReject,
                        visible: false,
                    }
                }
            }
        }
        case types.APPROVED_SHOP_EXCUTE: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    loading: true,
                }
            }
        }
        case types.REJECT_SHOP_EXCUTE: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    loading: true,
                }
            }
        }
        case types.APPROVED_SHOP_SUCCESS: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    loading: false,
                    visible: false,
                }
            }
        }
        case types.REJECT_SHOP_SUCCESS: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    loading: false,
                    visible: false,
                    modalReject: {
                        visible: false,
                    }
                }
            }
        }
        case types.APPROVED_SHOP_FAILED: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    visible: true,
                    loading: false,
                    errMessage: action.payload
                }
            }
        }
        case types.REJECT_SHOP_FAILED: {
            return {
                ...state,
                approvedShopModal: {
                    ...state.approvedShopModal,
                    visible: true,
                    loading: false,
                    errMessage: action.payload,
                    modalReject: {
                        visible: true,
                    }
                }
            }
        }
        default: 
            return state;       
    }
  }

export default ModalReducer;