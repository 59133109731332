import * as types from '../../constants/UserTypes'

const initialUsers = {
  data: {
      user: {
          list: [],
          total: 0,
          page: 1,
          size: 20
      },
      traveler: {
          list: [],
          total: 0,
          page: 1,
          size: 20
      },
      presenter: {
          list: [],
          total: 0,
          page: 1,
          size: 20
      },
      shop: {
          list: [],
          total: 0,
          page: 1,
          size: 20
      },
  },
  loading: true,
  messageErr: ''
};

const UserReducer = (state = initialUsers, action) => {
  switch (action.type) {
    //Action send request and wait
    case types.GET_USER_LIST:
    case types.GET_TRAVELER_LIST:
    case types.GET_PRESENTER_LIST:
    case types.GET_SHOP_LIST: {
        return { 
            ...state,
            loading: true
        }
    }

    case types.GET_USER_LIST_SUCCESS: {
        return {
            ...state,
            loading: false,
            data: {
                ...state.data,
                user: {
                    list: action.payload.content,
                    page: action.payload.page,
                    size: action.payload.size,
                    total: action.payload.total,
                }
            }
        }   
    }

    case types.GET_TRAVELER_LIST_SUCCESS: {
        return {
            ...state,
            loading: false,
            data: {
                ...state.data,
                traveler: {
                    list: action.payload.content,
                    page: action.payload.page,
                    size: action.payload.size,
                    total: action.payload.total,
                }
            }
        }   
    }

    case types.GET_PRESENTER_LIST_SUCCESS: {
        return {
            ...state,
            loading: false,
            data: {
                ...state.data,
                presenter: {
                    list: action.payload.content,
                    page: action.payload.page,
                    size: action.payload.size,
                    total: action.payload.total,
                }
            }
        }   
    }

    case types.GET_SHOP_LIST_SUCCESS: {
        return {
            ...state,
            loading: false,
            data: {
                ...state.data,
                shop: {
                    list: action.payload.content,
                    page: action.payload.page,
                    size: action.payload.size,
                    total: action.payload.total,
                }
            }
        }   
    }
    
    //Action request failed
    case types.GET_USER_LIST_FAILED:
    case types.GET_PRESENTER_LIST_FAILED:
    case types.GET_SHOP_LIST_FAILED:
    {
        console.log("failed", action)
        return {
            ...state,
            loading: false,
            messageErr: action.payload 
        }    
    }
    //Default
    default:
      return state;
  }
};

export default UserReducer;
