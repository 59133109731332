export const SHOW_MODAL_LOGOUT = 'SHOW_MODAL_LOGOUT';
export const HIDE_MODAL_LOGOUT = 'HIDE_MODAL_LOGOUT';
export const SHOW_MODAL_DELTE_USER = 'SHOW_MODAL_DELTE_USER';
export const HIDE_MODAL_DELTE_USER = 'HIDE_MODAL_DELTE_USER';
export const DELETE_USER_BY_ID = 'DELETE_USER_BY_ID';
export const DELETE_USER_BY_ID_SUCCESS = 'DELETE_USER_BY_ID_SUCCESS';
export const DELETE_USER_BY_ID_FAILED = 'DELETE_USER_BY_ID_FAILED';
export const SHOW_MODAL_EXPORT_CSV = 'SHOW_MODAL_EXPORT_CSV';
export const HIDE_MODAL_EXPORT_CSV = 'HIDE_MODAL_EXPORT_CSV';
export const SHOW_MODAL_APPROVED_SHOP = 'SHOW_MODAL_APPROVED_SHOP';
export const HIDE_MODAL_APPROVED_SHOP = 'HIDE_MODAL_APPROVED_SHOP';
export const GET_SHOP_INFO_MODAL_SUCCESS = 'GET_SHOP_INFO_MODAL_SUCCESS';
export const GET_SHOP_INFO_MODAL_FAILED = 'GET_SHOP_INFO_MODAL_FAILED';
export const APPROVED_SHOP_EXCUTE = 'APPROVED_SHOP_EXCUTE';
export const APPROVED_SHOP_SUCCESS = 'APPROVED_SHOP_SUCCESS';
export const APPROVED_SHOP_FAILED = 'APPROVED_SHOP_FAILED';
export const REJECT_SHOP_EXCUTE = 'REJECT_SHOP_EXCUTE';
export const REJECT_SHOP_SUCCESS = 'REJECT_SHOP_SUCCESS';
export const REJECT_SHOP_FAILED = 'REJECT_SHOP_FAILED';
export const SHOW_MODAL_REJECT_SHOP = 'SHOW_MODAL_REJECT_SHOP';
export const HIDE_MODAL_REJECT_SHOP = 'HIDE_MODAL_REJECT_SHOP';
export const SHOW_MODAL_EXPORT_CSV_SUCCESS = 'SHOW_MODAL_EXPORT_CSV_SUCCESS';
export const HIDE_MODAL_EXPORT_CSV_SUCCESS = 'HIDE_MODAL_EXPORT_CSV_SUCCESS';