// export const DemoTelecom = (props) => {
//     return <form action="https://secure.telecomcredit.co.jp/inetcredit/secure/cont.pl" method="POST">
//         <input type="hidden" name="clientip" value="00242" />
//         <input type="hidden" name="sendid" value="01330370" />
//         <input type="hidden" name="member_id" value="01330370" />
//         <input type="hidden" name="money" value="1000" />
//         <input type="hidden" name="usrmail" value="verakondrykina@gmailwe.com" />
//         <input type="hidden" name="usrtel" value="0977277188" />
//         <input type="hidden" name="sendpass" value="" />
//         <input type="hidden" name="redirect_url" value="" />
//         <input type="hidden" name="redirect_back_url" value="" />
//         <input type="hidden" name="option" value={'isAuthCredit'} />
//         <input type="hidden" name="send_pass_bool" value="" />
//         <input type="hidden" name="non_duplication_id" value="" />
//         <input type="hidden" name="rebill_param_id" value="" />
//         <input type="submit" value="決済画面へ" />
//     </form>
// }

// Change card
// export const DemoTelecom = (props) => {
//     return <form action="https://secure.telecomcredit.co.jp/inetcredit/secure/change_member_info-order.pl" method="POST">
//         <input type="hidden" name="clientip" value="00242" />
//         <input type="hidden" name="sendid" value="56878753" />
//         {/* <input type="hidden" name="money" value="9000" /> */}
//         <input type="hidden" name="usrmail" value="thantrongdai94@gmail.com" />
//         {/* <input type="hidden" name="usrtel" value="0999999999" /> */}
//         <input type="hidden" name="sendpass" value="" />
//         <input type="hidden" name="redirect_url" value="" />
//         <input type="hidden" name="redirect_back_url" value="" />
//         <input type="hidden" name="option" value='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImdhZWwwQHl2Z3Njb3BlLmNvbSIsImlkIjoiYTdhYmRjZjUtYzcyOC1mZGQ5LThjNjMtMTJhYjRiMGExYzcxIiwiZGV2aWNlSWQiOiJBMTFBQ0ZFRi05RTc3LTQzMzItOTMyRS01N0QwODIyREYxRjUiLCJuYW1lIjoiIiwiaXNBY3RpdmUiOjEsImlhdCI6MTY3Mzg2MDQyNSwiZXhwIjoxNjc2NDUyNDI1fQ.da7RSKkjV0ar4TpL8A4GW4rj9monVmqMCuCXGYFppKo;' />
//         <input type="hidden" name="send_pass_bool" value="" />
//         <input type="hidden" name="non_duplication_id" value="" />
//         <input type="hidden" name="rebill_param_id" value="" />
//         <input type="submit" value="決済画面へ" />
//     </form>
// }

// Delete card
// export const DemoTelecom = (props) => {
//     return <form action="https://secure.telecomcredit.co.jp/inetcredit/secure/card_info_erases-order.pl" method="POST">
//         <input type="hidden" name="clientip" value="00242" />
//         <input type="hidden" name="sendid" value="56878753" />
//         <input type="hidden" name="usrmail" value="thantrongdai94@gmail.com" />
//         <input type="submit" value="カード情報削除画面へ" />
//     </form>
// }

//00220038
//


//Auth
export const DemoTelecom = (props) => {
    return <form action="https://secure.telecomcredit.co.jp/inetcredit/adult/order.pl" method="POST">
        <input type="hidden" name="clientip" value="00242" />
        {/* <input type="hidden" name="usrmail" value="longs3tong@gmail.com" /> */}
        {/* <input type="hidden" name="usrtel" value="0987654321" /> */}
        <input type="hidden" name="sendpass" value="" />
        <input type="hidden" name="redirect_url" value="" />
        <input type="hidden" name="redirect_back_url" value="" />
        <input type="hidden" name="option" value="isAuthCredit;eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImdhZWwwQHl2Z3Njb3BlLmNvbSIsImlkIjoiYTdhYmRjZjUtYzcyOC1mZGQ5LThjNjMtMTJhYjRiMGExYzcxIiwiZGV2aWNlSWQiOiJBMTFBQ0ZFRi05RTc3LTQzMzItOTMyRS01N0QwODIyREYxRjUiLCJuYW1lIjoiIiwiaXNBY3RpdmUiOjEsImlhdCI6MTY3Mzg2MDQyNSwiZXhwIjoxNjc2NDUyNDI1fQ.da7RSKkjV0ar4TpL8A4GW4rj9monVmqMCuCXGYFppKo;" />
        {/* <input type="hidden" name="option" value="isAuthCredit;bc357f1f-ca80-89cc-8aa8-225e96e70b5a;" /> */}
        <input type="hidden" name="send_pass_bool" value="" />
        <input type="hidden" name="non_duplication_id" value="" />
        <input type="hidden" name="rebill_param_id" value="" />
        <input type="submit" value="決済画面へ" />
    </form>
}

//xac thuc moi lan nhan 1 sendid -> check thay doi thong tin de ko can xac thuc lai
