import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import { useDispatch } from 'react-redux'
import { fetchProvince, fetchCategory } from '../appRedux/actions'

const App = ({ match }) => {
  const dispatch = useDispatch();
  const fetchGlobalVariable = () => {
    dispatch(fetchProvince());
    dispatch(fetchCategory());
  }
  useEffect(() => {
    fetchGlobalVariable();
  }, [])
  return (
  <div className="gx-main-content-wrapper custom-main-content">
    <Switch>
      <Route 
        path={`${match.url}history-service/:id`}
        component={asyncComponent(() => import("./HistoryService"))}
      />
      <Route
        path={`${match.url}user-detail/:id`}
        component={asyncComponent(() => import("./UserDetail"))}
      />
      <Route
        path={`${match.url}payment-management`}
        component={asyncComponent(() => import("./PaymentHistory"))}
      />
      <Route
        path={`${match.url}`}
        component={asyncComponent(() => import("./UserManager"))}
      />
    </Switch>
  </div>
)
};

export default App;
