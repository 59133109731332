import * as types from '../../constants/VariableTypes'

const initialVariable = {
    provinces: [],
    categories: [],
    loading: true,
    messageErr: ''
  };
  
  const VariableReducer = (state = initialVariable, action) => {
    switch (action.type) {
        //Action send request and wait
        case types.GET_PROVINCE_LIST:
        case types.GET_CATEGORY_LIST:
            return { 
                ...state,
                loading: true
            }
        case types.GET_PROVINCE_SUCCESS:
            return { 
                ...state,
                provinces: action.payload,
                loading: false
            }
        case types.GET_CATEGORY_SUCCESS:
            return { 
                ...state,
                categories: action.payload,
                loading: false
            }
        case types.GET_PROVINCE_FAILED:
        case types.GET_CATEGORY_FAILED:
            return { 
                ...state,
                loading: false
            }
        default : 
            return state;
        
    }
}

  export default VariableReducer;
